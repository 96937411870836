import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';

export default function PrivateRoute ({
  children,
}) {
  const { isLoggedIn } = useAuth();
  const location = useLocation

  return isLoggedIn()
    ? (
      <>{children}</>
    )
    : (
      <Navigate
        replace={true}
        to="/login"
        state={{ from: `${location.pathname}${location.search}` }}
      />
    );
}