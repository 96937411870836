import { lazy } from 'react';
import PrivateRoute from '@/components/PrivateRoute';

const AdPage = lazy(() => import('./pages/AdPage'));
const AdsPage = lazy(() => import('./pages/AdsPage'));
const CardPage = lazy(() => import('./pages/CardPage'));
const CreatePasswordPage = lazy(() => import('./pages/CreatePasswordPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
// const IndexPage = lazy(() => import('./pages/IndexPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const NoAccessPage = lazy(() => import('./pages/NoAccessPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const TeamPage = lazy(() => import('./pages/TeamPage'));
const TeamMemberPage = lazy(() => import('./pages/TeamMemberPage'));
const TopicsPage = lazy(() => import('./pages/TopicsPage'));
const TopicPage = lazy(() => import('./pages/TopicPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const UserPage = lazy(() => import('./pages/UserPage'));

const routes = [
  {
    path: 'cards/:id',
    element: <CardPage />,
  },
  {
    path: 'create-password/:token',
    element: <CreatePasswordPage />,
  },
  {
    path: '/',
    element: <PrivateRoute><DashboardPage /></PrivateRoute>,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: '403',
    element: <NoAccessPage />,
  },
  {
    path: 'advertising',
    element: <PrivateRoute><AdsPage /></PrivateRoute>,
  },
  {
    path: 'advertising/:id',
    element: <PrivateRoute><AdPage /></PrivateRoute>,
  },
  {
    path: 'notifications',
    element: <PrivateRoute><NotificationsPage /></PrivateRoute>,
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: 'reset-password/:token',
    element: <CreatePasswordPage />,
  },
  {
    path: 'settings',
    element: <PrivateRoute><SettingsPage /></PrivateRoute>,
  },
  // {
  //   path: 'stats',
  //   element: <PrivateRoute><DashboardPage /></PrivateRoute>,
  // },
  {
    path: 'team',
    element: <PrivateRoute><TeamPage /></PrivateRoute>,
  },
  {
    path: 'team/:id',
    element: <PrivateRoute><TeamMemberPage /></PrivateRoute>,
  },
  {
    path: 'topics',
    element: <PrivateRoute><TopicsPage /></PrivateRoute>,
  },
  {
    path: 'topics/:id',
    element: <PrivateRoute><TopicPage /></PrivateRoute>,
  },
  {
    path: 'users',
    element: <PrivateRoute><UsersPage /></PrivateRoute>,
  },
  {
    path: 'users/:id',
    element: <PrivateRoute><UserPage /></PrivateRoute>,
  },

  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default routes;
