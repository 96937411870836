import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '@/services/axios';
import i18n from "@/i18n";
// import { useNavigation } from 'react-router-dom';

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('access_token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('auth'))?.user || null);

  // const navigation = useNavigation();

  // Sync with localStorage on state change
  useEffect(() => {
    if (!user) {
      localStorage.removeItem('auth');
    } else {
      localStorage.setItem('auth', JSON.stringify({ user }));
    }
  }, [user]);

  useEffect(() => {
    if (!token) {
      localStorage.removeItem('access_token');
    } else {
      localStorage.setItem('access_token', token);
    }
  }, [token]);

  const login = (email, password) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios.post(`/admin/login`, { email, password })
        .then((response) => {
          setUser(response.data.user);
          setToken(response.data.access_token);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const logout = (isLoggedOut = false) => {
    if (isLoggedOut) {
      setUser(null);
      setToken(null);
      if (window.location.pathname === '/login') return;
      // navigation.navigate('/login');
      window.location.href = '/login';
      return;
    }

    setLoading(true);
    return new Promise((resolve, reject) => {
      axios.post(`/auth/logout`)
        .then((response) => {
          setUser(null);
          setToken(null);
          resolve(response.data);
          // navigation.navigate('/login');
          window.location.href = '/login';
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const update = () => {
    if (!user?.id) return;

    return new Promise((resolve, reject) => {
      axios.put(`/admin/users/${user.id}`, user)
        .then((response) => {
          setUser(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const patch = (data) => {
    if (!user?.id) return;

    setLoading(true);
    return new Promise((resolve, reject) => {
      axios.patch('/account', data)
        .then((response) => {
          setUser(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const set = (key, value) => {
    setUser((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const language = user?.language || 'et';

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const me = () => {
    axios.get('/auth/me')
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <AuthContext.Provider value={{
      loading,
      token,
      user,

      canAddTopic: () => user?.role === 'super-admin' || user?.can_add_topic,
      isLoggedIn: () => !!user,
      isSuperAdmin: () => user?.role === 'super-admin',

      login,
      logout,
      me,
      patch,
      set,
      update
    }}>
      {children}
    </AuthContext.Provider>
  );
};
